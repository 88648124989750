export const houses = [
  {
    id: 14242,
    price: 9000000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "casa",
    type: "venta",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
  },
  {
    id: 14243,
    price: 500000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "apt",
    type: "alquiler",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://www.coralbayrealestate.com/media/com_iproperty/pictures/amber_dume_15e2f0cb3a24ee.jpg",
  },
  {
    id: 14244,
    price: 7600000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "casa",
    type: "venta",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://www.goldenkeymanagement.com/wp-content/uploads/2022/01/puertoplatahouse2511-aerial02-488x326.jpg",
  },
  {
    id: 14245,
    price: 9000000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "apt",
    type: "alquiler",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
  },
  {
    id: 14245,
    price: 9000000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "apt",
    type: "venta",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
  },
  {
    id: 14242,
    price: 9000000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "casa",
    type: "venta",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
  },
  {
    id: 14243,
    price: 500000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "apt",
    type: "alquiler",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://www.coralbayrealestate.com/media/com_iproperty/pictures/amber_dume_15e2f0cb3a24ee.jpg",
  },
  {
    id: 14244,
    price: 7600000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "casa",
    type: "venta",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://www.goldenkeymanagement.com/wp-content/uploads/2022/01/puertoplatahouse2511-aerial02-488x326.jpg",
  },
  {
    id: 14245,
    price: 9000000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "apt",
    type: "alquiler",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
  },
  {
    id: 14245,
    price: 9000000,
    rooms: 4,
    bath: 2,
    photoList: [
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151445jw6oA2uj.jpg",
      "https://indominicana.com/private/properties/files/23872/medium/20220125151446m7mMXU6G.jpg",
    ],
    address: {
      line: "Calle 12, El Ensueno",
      ciudad: "Santiago de los Caballeros",
      provincia: "Santiago",
    },
    category: "apt",
    type: "venta",
    available: "yes",
    parqueo: 2,
    alt: "casa",
    img: "https://indominicana.com/private/properties/files/23872/medium/20220125151446cylLFGs1.jpg",
  },
];
