import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../config/colors";

export const Button = styled(Link)`
  background-color: ${colors.primary};
  /* background-color: ${({ primary }) => (primary ? "black" : "CD853F")} */
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.$big ? "16px 40px" : "14px 24px")};
  color: ${(props) => (props.$primary ? "#fff" : "#000D1A")};
  /* font-size: ${({ big }) => (big ? "20px" : "14px")}; */
  &:hover {
    transform: translateY(-2px);
  }
`;
