import React from "react";

export default function NotFound() {
  return (
    <div>
      NOT FOUND NOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT
      FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT
      FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT
      FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT
      FOUNDNOT FOUNDNOT FOUNDNOT FOUNDNOT FOUND
    </div>
  );
}
